.App {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #11142D;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
